import classNames from "classnames";
import { html, useEffect, useState } from "haunted";
import { CUG3_LOGIN_URL } from "Services/constants";

export const useShadowDOM = false;
export const name = "cug3-login-handler";

const loaderClassName = "dg-for-cug3-login-loader";

export const Component = () => {
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const loader = window.newRtLoader(`.${loaderClassName}`);
		if (loader) {
			loader.startLoader();
		}

		const params = new URLSearchParams(window.location.search);
		const session = params.get("session");
		const iv = params.get("iv");

		const formData = new FormData();
		formData.append("session", session);
		if (iv) {
			formData.append("iv", iv);
		}

		fetch(`${window.JetSmart.Settings.BookingUrl}/${CUG3_LOGIN_URL}`, {
			method: "POST",
			redirect: "follow",
			body: formData,
			credentials: "include",
		})
			.then((res) => {
				if (!res.ok) {
					setError(`Error logging in: ${res.statusText}`);
					return;
				}

				window.location.href = "/changeLanguage?newCountryCode=cl&newLanguageCode=es";
			})
			.catch((err) => {
				setError(`Error logging in: ${err?.message}`);
			})
			.finally(() => {
				if (loader) {
					loader.stopLoader();
					loader.destroy();
				}
			});
	}, []);

	const errorTemplate = error ? html`<div class="mt-4 text-red-500">${error}</div>` : "";

	return html`<div class=${classNames("min-h-svh", loaderClassName)}>${errorTemplate}</div>`;
};
