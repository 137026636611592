import { html } from "haunted";
import { useEffect } from "Shared/haunted/CustomHooks";
import { HauntedFunc } from "Shared/haunted/HooksHelpers";

export const useShadowDOM = false;
export const name = "ac-smart-timer";

export const observedAttributes: (keyof Attributes)[] = ["seconds", "url"];

export interface Attributes {
	seconds: string;
	url: string;
}

export interface Props {
	seconds: number;
	url: string;
}

export const Component: HauntedFunc<Props> = (host) => {
	const props: Props = {
		seconds: Number(host.seconds),
		url: host.url,
	};

	const init = () => {
		window.setTimeout(() => {
			window.location.href = props.url;
		}, Number(props.seconds) * 1000);
	};

	useEffect(init, []);

	return html``;
};
