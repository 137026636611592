import { useEffect as hauntedUseEffect, useMemo as hauntedUseMemo, useState as hauntedUseState } from "haunted";

// class ClientManagerHook extends Hook {
// 	private readonly abortController = new AbortController();
// 	private readonly clientManager: ClientManager;
// 	constructor(id: number, state: State) {
// 		super(id, state);
// 		this.clientManager = new ClientManager(this.abortController.signal);
// 	}

// 	public update(): ClientManager {
// 		return this.clientManager;
// 	}

// 	public teardown() {
// 		this.abortController.abort();
// 	}
// }

// class ClientHook<T> extends Hook {
// 	private readonly abortController = new AbortController();
// 	private readonly client: T;
// 	constructor(id: number, state: State, ctor: ClientConstructor<T>) {
// 		super(id, state);
// 		this.client = new ClientManager(this.abortController.signal).createClient(ctor);
// 	}

// 	public update(): T {
// 		return this.client;
// 	}

// 	public teardown() {
// 		this.abortController.abort();
// 	}
// }

// class QueryStateManagerHook<T extends string | number | Date> extends Hook {
// 	private args!: readonly [T, (value: T) => void];
// 	private readonly key: string;
// 	constructor(id: number, state: State, key: string, initialValue: T) {
// 		super(id, state);
// 		this.updater = this.updater.bind(this);
// 		this.key = key;
// 		const parameter = Router.getQueryParameter(key);
// 		if (parameter !== undefined) {
// 			if (initialValue instanceof Date) {
// 				this.makeArgs(parseTimestamp(parameter).toDate() as T);
// 			} else if (typeof initialValue === "number") {
// 				this.makeArgs(parseInt(parameter, 10) as T);
// 			} else if (typeof initialValue === "string") {
// 				this.makeArgs(parameter as T);
// 			} else {
// 				this.makeArgs(parameter as T);
// 			}
// 		} else {
// 			this.makeArgs(initialValue);
// 		}
// 	}

// 	public update(): readonly [T, (value: T) => void] {
// 		return this.args;
// 	}

// 	private makeArgs(value: T): void {
// 		this.args = Object.freeze([value, this.updater.bind(this)] as const);
// 	}

// 	private updater(value: T): void {
// 		if (value instanceof Date) {
// 			Router.updateParameter(this.key, formatTimestamp(value));
// 		} else if (typeof value === "number") {
// 			Router.updateParameter(this.key, value.toString());
// 		} else if (typeof value === "string") {
// 			Router.updateParameter(this.key, value);
// 		}
// 		this.makeArgs(value);
// 		this.state.update();
// 	}
// }

export const useState = hauntedUseState;
export const useMemo = hauntedUseMemo;
// export const useClient = hook(ClientHook);
// export const useClientManager = hook(ClientManagerHook);
// export const useQueryState = hook(QueryStateManagerHook);

export const useEffect = (
	callback: () => Promise<(() => void) | void> | ((() => void) | void),
	values?: unknown[] | undefined
): void => {
	hauntedUseEffect(() => {
		// setTimeout is used, because useEffect can cascade between embedded components and can cause an update skipped (such as an "initial render")
		// const result = callback();
		//eslint-disable-next-line @typescript-eslint/no-misused-promises
		window.setTimeout(() => {
			const result = callback();
			if (result instanceof Promise) {
				result.catch(async (reason) => {
					// eslint-disable-next-line no-console
					console.log(reason);
				});
			}
		}, 0);
	}, values);
};

// export const useValidationContext = <VM, FN extends string>(validator: AbstractValidator<VM>) => {
// 	const [validationResult, setValidationResult] = useState<DCValidationResult<FN>>(new DCValidationResult<FN>());
// 	const [fieldsExplicitlyValidated, setFieldsExplicitlyValidated] = useState<{ [key in FN]?: boolean }>({});
// 	const [newFieldsToAddAtValidation, setNewFieldsToAddAtValidation] = useState<FN[]>([]);

// 	const addValidationFieldsAtValidation = (fieldNames: FN[]) => {
// 		setNewFieldsToAddAtValidation(fieldNames);
// 	};

// 	const addValidationFields = () => {
// 		if (newFieldsToAddAtValidation.length > 0) {
// 			const newValidationFields = { ...fieldsExplicitlyValidated };
// 			newFieldsToAddAtValidation.forEach((fieldName) => {
// 				newValidationFields[fieldName] = true;
// 			});
// 			setFieldsExplicitlyValidated(newValidationFields);
// 			setNewFieldsToAddAtValidation([]);
// 		}
// 	};

// 	return {
// 		validate: (viewModel: VM): DCValidationResult<FN> => {
// 			addValidationFields();
// 			const validationResult2 = new DCValidationResult<FN>(validator.validate(viewModel));
// 			setValidationResult(validationResult2);
// 			return validationResult2;
// 		},
// 		validateField: (fieldName: FN) => {
// 			addValidationFieldsAtValidation([fieldName]);
// 		},
// 		getValidationMessage: (fieldName: FN) => {
// 			const msg = fieldsExplicitlyValidated[fieldName] ? validationResult.getValidationMessage(fieldName) : "";
// 			return msg;
// 		},
// 		validateFields: addValidationFieldsAtValidation,
// 		clearFieldValidations: () => {
// 			setFieldsExplicitlyValidated({});
// 		},
// 		validationResult,
// 	};
// };
