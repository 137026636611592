import { html } from "haunted";
import i18next from "i18next";
import { useState, useEffect } from "Shared/haunted/CustomHooks";
import { HauntedFunc } from "Shared/haunted/HooksHelpers";
import { PUB_SUBS } from "Services/pub-sub-service/PubSub";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { deletePcraLoginCookie } from "Services/cookieHandling";

export const useShadowDOM = false;
export const name = "ac-pcra-login-info-modal";

export type PcraLoginInfoModalType = "none" | "gestor" | "delegado";

export const Component: HauntedFunc<{}> = () => {
	const getUserTitle = () => (type === "gestor" ? "Gestor" : "Delegado");

	//COMPONENTS

	const [type, setType] = useState<PcraLoginInfoModalType>("none");

	useEffect(() => {
		const sub = PUB_SUBS.PcraLoginInfoModalOpened.subscribe((e) => setType(e.modalType));
		return () => sub.unsubscribe();
	}, []);

	//HANDLERS:

	const handleAcceptClick = () => {
		deletePcraLoginCookie();
		setType("none");
	};

	//TEMPLATES:

	const bannerTemplate = () => {
		const userType = getUserTitle();

		return html`
			<div class="dg-new-modal-info-bar pcra-login-modal-header">
				${unsafeHTML(
					i18next.t("Sr. {{-userType}}", {
						userType,
					})
				)}
			</div>
		`;
	};

	const loginInfoModalMessageTemplate = () =>
		type === "gestor"
			? html`
					<p>${i18next.t("Login-Info-Modal-Gestor-Paragraph-1")}</p>
					<p>${i18next.t("Login-Info-Modal-Gestor-Paragraph-2")}</p>
			  `
			: html`
					<p>${i18next.t("Login-Info-Modal-Delegado-Paragraph-1")}</p>
					<p>${i18next.t("Login-Info-Modal-Delegado-Paragraph-2")}</p>
			  `;

	const contentTemplate = () => html` <div class="pcra-login-info-content">${loginInfoModalMessageTemplate()}</div> `;

	const footerTemplate = () =>
		html`
			<div class="pcra-modal-footer">
				<button class="dg-rounded-primary-btn" @click=${handleAcceptClick}>${i18next.t("Aceptar")}</button>
			</div>
		`;

	return type !== "none"
		? html` <div class="dg-new-modal">
				<div class="dg-new-modal-content pcra-login-info-modal" data-test-id="modal-content">
					${bannerTemplate()} ${contentTemplate()} ${footerTemplate()}
				</div>
		  </div>`
		: html``;
};
