export const createHeaders = () => {
	const headers = new Headers({
		Accept: "application/json",
	});

	if (window.JetSmart.NavitaireSettingsOptions.Mode === "DirectFareCache") {
		headers.append("x-api-key", window.JetSmart.NavitaireSettingsOptions.ApiKey);
	}

	return headers;
};