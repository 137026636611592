import { ApiCultureSettings } from "ComponentModels/ApiCultureSettings";
import { CultureListItem } from "Components/main-menu/useCultureSelector";

const insensitiveEquals = (string1: string, string2: string) => {
	return string1?.trim().toLowerCase() === string2?.trim().toLowerCase();
};

const mainCultureSubCultureCodes = (settings: ApiCultureSettings, currentCultureCode: string): string[] =>
	settings?.Cultures?.find((culture) => insensitiveEquals(culture.Main, currentCultureCode))?.SubCultures || [];

const nonMainCultureSubCultureCodes = (settings: ApiCultureSettings, currentCultureCode: string): string[] => {
	const mainCultureSubCultures = mainCultureSubCultureCodes(settings, currentCultureCode);

	return (
		settings?.AllCultures.filter(
			(cultureCode) =>
				!mainCultureSubCultures.some((subCultureCode) => insensitiveEquals(subCultureCode, cultureCode))
		) || []
	);
};

const convertCodesToCultureListItems = (cultureList: CultureListItem[], cultureCodes: string[]): CultureListItem[] =>
	cultureCodes.map((cultureCode) => mapToCultureListItem(cultureList, cultureCode)).filter((listItem) => listItem);

const getSelectableMainCultureSubCultures = (
	settings: ApiCultureSettings,
	cultureList: CultureListItem[],
	currentCulture: string
): CultureListItem[] =>
	convertCodesToCultureListItems(cultureList, mainCultureSubCultureCodes(settings, currentCulture));

const getSelectableNonMainCultureSubCultures = (
	settings: ApiCultureSettings,
	cultureList: CultureListItem[],
	currentCulture: string
): CultureListItem[] =>
	convertCodesToCultureListItems(cultureList, nonMainCultureSubCultureCodes(settings, currentCulture)).sort((a, b) =>
		a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
	);

export const mapToCultureListItem = (cultureList: CultureListItem[], cultureCode: string) =>
	cultureList.find((listItem) => insensitiveEquals(listItem.culture, cultureCode));

export const isCultureEnabled = (settings: ApiCultureSettings, cultureCode: string) =>
	!settings?.Cultures.some((culture) => culture.IsDisabled && insensitiveEquals(culture.Main, cultureCode));

export const getSelectableCulturesInOrder = (
	settings: ApiCultureSettings,
	cultureList: CultureListItem[],
	currentCultureCode: string
): CultureListItem[] => {
	if (!settings) return cultureList;

	const mainCultureSubCultures = getSelectableMainCultureSubCultures(settings, cultureList, currentCultureCode);
	const remainingSubCultures = getSelectableNonMainCultureSubCultures(settings, cultureList, currentCultureCode);

	return [...mainCultureSubCultures, ...remainingSubCultures];
};
