import { useState } from "Shared/haunted/CustomHooks";
import { html } from "haunted";
import i18next from "i18next";
import { tealiumLogSelectOneWayType, tealiumLogSelectReturnType } from "ComponentHelpers/SearchboxTealiumHelpers";
import { TestIdDictionary } from "Services/test-ids/TestIdDictionary";
import {
	eventBusTriggerSelectOneWayType,
	eventBusTriggerSelectReturnType,
} from "ComponentHelpers/SearchboxEventBusHelpers";
import classNames from "classnames";

export interface Props {
	isHidden: boolean;
}

const oneWayId = "sbOneWayJourney";
const returnId = "sbReturnJourney";

export const useTripTypeSelector = (props: Props) => {
	const [numberOfJourneys, setNumberOfJourneys] = useState<number>(2);

	const handleReturn = (e?: MouseEvent) => {
		e?.preventDefault();
		e?.stopPropagation();

		setNumberOfJourneys(2);
		tealiumLogSelectReturnType();
		eventBusTriggerSelectReturnType();
		window.setTimeout(() => {
			// DEVNOTE Haunted bug(?)
			(document.getElementById(returnId) as HTMLInputElement).checked = true;
			(document.getElementById(oneWayId) as HTMLInputElement).checked = false;
		}, 0);
	};

	const handleOneWay = (e?: MouseEvent) => {
		e?.preventDefault();
		e?.stopPropagation();

		setNumberOfJourneys(1);
		tealiumLogSelectOneWayType();
		eventBusTriggerSelectOneWayType();
		window.setTimeout(() => {
			// DEVNOTE Haunted bug(?)
			(document.getElementById(returnId) as HTMLInputElement).checked = false;
			(document.getElementById(oneWayId) as HTMLInputElement).checked = true;
		}, 0);
	};

	const updateOnSearchReload = (newNumberOfJourneys: number) => {
		if (newNumberOfJourneys === 1) {
			handleOneWay();
		} else {
			handleReturn();
		}
	};

	const htmlTemplate = () => html` <div
		class=${classNames("dg-journey-type-selector", { "hidden-xs": props.isHidden })}
	>
		<div class="row">
			<div class="col-xs-1-2 col-sm-1-2 col-md-1-2 text-center">
				<input
					type="radio"
					name="journeyType"
					id=${returnId}
					autocomplete="off"
					checked
					@click=${handleReturn}
				/>
				<label for=${returnId}>${i18next.t("search-box-roundtrip")}</label>
			</div>
			<div class="col-xs-1-2 col-sm-1-2 col-md-1-2 text-center">
				<input
					type="radio"
					name="journeyType"
					id=${oneWayId}
					autocomplete="off"
					data-test-id=${TestIdDictionary.Date.OneWaySelector}
					@click=${handleOneWay}
				/>
				<label for=${oneWayId}>${i18next.t("search-box-oneway")}</label>
			</div>
		</div>
	</div>`;

	return { numberOfJourneys, htmlTemplate, updateOnSearchReload };
};
