import { ApiStations, ApiCountry, ApiStation } from "Shared/models/ApiStations";
import { API_STATION_URL, CHILE_COUNTRY_CODE } from "Services/constants";
import { RouteSelectorOpts } from "ComponentModels/RouteSelectorOpts";
import { locale } from "Services/Globals";
import { useState } from "haunted";
import { useNavitaireSettings } from "./useNavitaireSettings";
import { useEffect } from "Shared/haunted/CustomHooks";
import { removeTrailingSlash } from "Services/common";
import { createHeaders } from "./HeaderHelper";
import { useStationOrderStrategyFactory } from "./Strategies/useStationOrderStrategyFactory";
import { useUserInfo } from "./useUserInfo";

interface StationProps {
	opts: RouteSelectorOpts;
	startLoading: () => LoaderInstance;
	stopLoading: (loader: LoaderInstance) => void;
}

export const useStations = (props: StationProps): [ApiCountry[], (city: ApiStation) => ApiCountry[]] => {
	const navitaireSettings = useNavitaireSettings();
	const factory = useStationOrderStrategyFactory(props.opts.culture);

	const [countryStations, setCountryStations] = useState<ApiCountry[]>([]);
	const [loader, setLoader] = useState<LoaderInstance>();

	const userInfo = useUserInfo();

	const isCountryAvailable = (apiCountry: ApiCountry): boolean => {
		if (!navitaireSettings?.value?.stationSettings) throw new Error("No StationSettings");

		return navitaireSettings?.value?.stationSettings.Countries.some(
			(country) =>
				country.Code.toLowerCase() === apiCountry.information.code.toLowerCase() &&
				!country.IsHidden &&
				((!userInfo.IsPeruCompraMember && !userInfo.IsPeruCompraAdmin) || country.IsAvailableForPeruCompras) &&
				(country.Code.toLowerCase() === CHILE_COUNTRY_CODE.toLowerCase() ||
					(!userInfo.IsCug3Observer && !userInfo.IsCug3Supervisor))
		);
	};

	const availableDestinationCountriesForStation = (city: ApiStation): ApiCountry[] =>
		countryStations
			.map((country) => ({
				...country,
				regions: country.regions
					.map((region) => ({
						...region,
						stations: region.stations.filter((station) => city.markets.includes(station.information.code)),
					}))
					.filter((region) => region.stations.length > 0),
			}))
			.filter((country) => country.regions.length > 0);

	const loadApiCountries = async (): Promise<ApiCountry[]> => {
		const headers = createHeaders();

		const url = createStationsUrl();
		const response = await fetch(url, { headers });

		if (response.ok) {
			const responseJson = (await response.json()) as ApiStations;

			return responseJson.countries;
		} else {
			throw new Error("Network response was not ok.");
		}
	};

	const createStationsUrl = () => {
		if (window.JetSmart.StationsOptions.Mode === "DirectFareCache") {
			const baseUrl = removeTrailingSlash(window.JetSmart.StationsOptions.BaseAddress);

			return `${baseUrl}/stations?culture=${locale}`;
		}

		return `${API_STATION_URL}?locale=${locale}`;
	};

	const loadStations = async () => {
		setLoader(props.startLoading());
		const countries = await loadApiCountries();
		const strategy = factory.create();
		const orderedCountries = strategy().orderStations(countries);
		await strategy().postStep();
		const filteredCountries = orderedCountries.filter(isCountryAvailable);

		setCountryStations(filteredCountries);
	};

	useEffect(async () => {
		if (navitaireSettings?.value?.abTestSettings && navitaireSettings?.value?.stationSettings) {
			await loadStations();
		}
	}, [navitaireSettings?.value]);

	useEffect(() => {
		if (countryStations?.length > 0 && loader) props.stopLoading(loader);
	}, [countryStations]);

	return [countryStations, availableDestinationCountriesForStation];
};
