import { AMERICAN_AIRLINES_TIERS, COOKIES, WebAnonymousRoleCode } from "Services/constants";
import { getCookie } from "Services/cookieHandling";
import { UserInfo } from "Shared/models/UserInfo";
import { decode } from "./PromoCodeHelper";
import { UserInfoCookie } from "Shared/models/UserInfoCookie";
import { toBoolean } from "Services/common";
import { useMemo } from "Shared/haunted/CustomHooks";

// FOR TESTING ONLY! REMOVE BEFORE GOING TO PROD!
// const FAKE_USER_INFO_FOR_TESTING: UserInfo = {
// 	BancoEstadoCategory: 0,
// 	BancoEstadoFreeSeats: 0,
// 	IsBancoEstadoMember: false,
// 	IsCug3Observer: false,
// 	IsCug3Supervisor: true,
// 	IsDiscountClubGroupMember: false,
// 	IsDiscountClubStandardMember: false,
// 	IsPeruCompraAdmin: false,
// 	IsPeruCompraMember: false,
// 	Name: "CUG3 Test User",
// 	Organization: "CUG3 Test Organization",
// 	PeruCompraAmount: "",
// 	ProgramCodesWithLevels: [],
// 	ProgramLevels: "",
// 	RoleCode: "ABABAB",
// };
// EOF FOR TESTING ONLY

export const useUserInfo = (): UserInfo => {
	const retVal = useMemo(() => {
		const userInfo = getCookie(COOKIES.USER_INFO);

		try {
			const decodedUserInfo = userInfo
				? (JSON.parse(decode(atob(userInfo)), (key, value) => {
						if (key === "Tier") {
							return AMERICAN_AIRLINES_TIERS[String.fromCharCode(value)];
						}
						return value;
				  }) as UserInfoCookie)
				: undefined;

			const parsedUserInfo: UserInfo = {
				BancoEstadoCategory: !isNaN(Number(decodedUserInfo?.BancoEstadoCategory))
					? Number(decodedUserInfo?.BancoEstadoCategory || 0) || 0
					: 0,
				BancoEstadoFreeSeats: !isNaN(Number(decodedUserInfo?.BancoEstadoCategory))
					? Number(decodedUserInfo?.BancoEstadoFreeSeats || 0) || 0
					: 0,
				IsBancoEstadoMember: toBoolean(decodedUserInfo?.IsBancoEstadoMember),
				IsCug3Observer: toBoolean(decodedUserInfo?.IsCug3Observer),
				IsCug3Supervisor: toBoolean(decodedUserInfo?.IsCug3Supervisor),
				IsDiscountClubGroupMember: toBoolean(decodedUserInfo?.IsDiscountClubGroupMember),
				IsDiscountClubStandardMember: toBoolean(decodedUserInfo?.IsDiscountClubStandardMember),
				IsPeruCompraAdmin: toBoolean(decodedUserInfo?.IsPeruCompraAdmin),
				IsPeruCompraMember: toBoolean(decodedUserInfo?.IsPeruCompraMember),
				Name: decodedUserInfo?.Name || "",
				Organization: decodedUserInfo?.Organization || "",
				PeruCompraAmount: decodedUserInfo?.PeruCompraAmount || "",
				ProgramCodesWithLevels: decodedUserInfo?.ProgramCodesWithLevels?.split("|") || [],
				ProgramLevels: decodedUserInfo?.ProgramLevels || "",
				RoleCode: decodedUserInfo?.RoleCode || WebAnonymousRoleCode,

				IsAmericanMember: toBoolean(decodedUserInfo?.IsAmericanMember),
				Miles: !isNaN(Number(decodedUserInfo?.Miles)) ? Number(decodedUserInfo?.Miles || 0) || 0 : 0,
				AmericanAirlinesDisplayName: decodedUserInfo?.AmericanAirlinesDisplayName
					? `${decodedUserInfo?.AmericanAirlinesDisplayName.FirstName} ${decodedUserInfo?.AmericanAirlinesDisplayName.LastName}`
					: "",
				AmericanMemberTier: decodedUserInfo?.Tier || undefined,
				EligibleForAmericanAirlinesRedemption: toBoolean(
					decodedUserInfo?.EligibleForAmericanAirlinesRedemption
				),
				AmericanAirlinesAdvantageNumber: decodedUserInfo?.AmericanAirlinesAdvantageNumber || "",
			};

			return parsedUserInfo;
		} catch (e) {
			throw new Error("Unable to parse user info cookie.");
		}
	}, []);

	// FOR TESTING ONLY! REMOVE BEFORE GOING TO PROD!
	// return FAKE_USER_INFO_FOR_TESTING || retVal;
	// EOF FOR TESTING ONLY
	return retVal;
};
