import { COMMON_DAYJS_FORMAT, COOKIES } from "Services/constants";
import { getCookie, setCookieWithExpiry } from "Services/cookieHandling";
import * as dayjs from "dayjs";
import * as UTC from "dayjs/plugin/utc";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
dayjs.extend(UTC);

export interface StoredSearch {
	departureDate: string;
	destination: string;
	lastVisit?: string;
	origin: string;
	passengers: {
		adults: number;
		children: number;
		infants: number;
		teens: number;
	};
	returnDate: string;
}

export const usePreviousSearch = () => {
	const save = (value: StoredSearch) => {
		try {
			setCookieWithExpiry(
				COOKIES.PREVIOUS_SEARCH,
				btoa(JSON.stringify({ ...value, lastVisit: dayjs().utc().format(COMMON_DAYJS_FORMAT) })),
				14
			);
		} catch {
			// Do nothing
		}
	};

	const load = (): StoredSearch => {
		const rawValue = getCookie(COOKIES.PREVIOUS_SEARCH);

		if (!rawValue) return undefined;

		try {
			return JSON.parse(atob(rawValue)) as StoredSearch;
		} catch {
			return undefined;
		}
	};

	const refresh = () => {
		const oldValue = load();
		if (oldValue) save(oldValue);
	};

	return { load, save, refresh };
};
