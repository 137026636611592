import { CLASS_NAMES } from "Services/ClassNames";
import classNames from "classnames";
import { html } from "lit-html";

export interface PaxSelectorProps {
	ageNote: string;
	amount: number;
	amountTestId: string;
	decreaseTestId: string;
	increaseTestId: string;
	isIncreaseDisabled: boolean;
	label: string;
	onDecrease: () => void;
	onIncrease: () => void;
}

export const usePax = (props: PaxSelectorProps) => {
	const handleIncrease = (e: MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();

		props.onIncrease();
	};

	const handleDecrease = (e: MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();

		props.onDecrease();
	};

	const htmlTemplate = () => html`
		<div class="dg-quantity-container">
			<div class="dg-pax-amount">
				<div
					class=${classNames("dg-quantity-btn decrease", { [CLASS_NAMES.disabled]: props.amount === 0 })}
					title="Decrease Quantity"
					data-test-id=${props.decreaseTestId}
					@click=${handleDecrease}
				>
					-
				</div>
				<span class="dg-quantity-amount" data-test-id=${props.amountTestId}>${props.amount}</span>
				<div
					class=${classNames("dg-quantity-btn increase", {
						[CLASS_NAMES.disabled]: props.isIncreaseDisabled,
					})}
					title="Increase Quantity"
					data-test-id=${props.increaseTestId}
					@click=${handleIncrease}
				>
					+
				</div>
				<div class="dg-quantity-label">${props.label}</div>
				<span class="dg-age-label">&nbsp;${props.ageNote}</span>
			</div>
		</div>
	`;

	return { htmlTemplate };
};
