import { useEffect, useState } from "Shared/haunted/CustomHooks";

export type SingletonType = "NavitaireSettings" | "PaneMode" | "LastOpenPaneMode";

const callbacks = new Map<{ type: SingletonType; uniqueId: number }, Function>();
const values = new Map<SingletonType, any>();

export interface Props {
	type: SingletonType;
}

// DEVNOTE
// This hook is used to create a global variable that can only be initialized once.
// The reason is to have the possibility to use the global variable as a dependency
// in multiple components without having to pass it as a prop, and make it cause
// a re-render whenever it changes (module scope or global variables don't
// cause re-renders).

export const useSingleton = <T>(props: Props) => {
	const [value, setValue] = useState<T>(values.get(props.type));

	const setUp = () => {
		const key = { type: props.type, uniqueId: Date.now() };
		callbacks.set(key, setValue);
		setValue(values.get(props.type));

		return () => {
			values.delete(props.type);
			callbacks.delete(key);
		};
	};

	const init = async (initFn: () => Promise<T>) => {
		if (values.has(props.type)) return;

		values.set(props.type, undefined);
		const initResult = await initFn();
		update(initResult);
	};

	const update = (newValue: T) => {
		values.set(props.type, newValue);
		Array.from(callbacks.entries())
			.filter(([key, _]) => key.type === props.type)
			.forEach(([_, callback]) => callback(newValue));
	};

	useEffect(setUp, []);

	return { value, init, update };
};
