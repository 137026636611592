import { areEqual } from "Shared/helpers/stringHelper";
import { ApiCountry, ApiRegion, ApiStation } from "Shared/models/ApiStations";
import { ApiStationSettings, LocalizedString } from "Shared/models/ApiStationSettings";
import { IStationOrderStrategy } from "./useStationOrderStrategyFactory";

export const resourceXmlOrderStrategy = (
	stationSettings: ApiStationSettings,
	culture: string
): (() => IStationOrderStrategy) => {
	const getRegionName = (name: LocalizedString) => {
		if (culture.startsWith("en")) return name.En;
		if (culture.startsWith("pt")) return name.Pt;

		return name.Es;
	};

	const getMarkets = (countries: ApiCountry[]): { [stationCode: string]: string[] } =>
		getAllStations(countries)
			.map((station) => ({ [station.information.code]: station.markets }))
			.reduce((markets, market) => ({ ...markets, ...market }), {});

	const getAllStations = (countries: ApiCountry[]): ApiStation[] =>
		countries.flatMap((c) => c.regions.flatMap((r) => r.stations));

	// TODO Further refactor this
	return () => ({
		orderStations: (countries: ApiCountry[]): ApiCountry[] => {
			if (window.JetSmart.StationsOptions.Mode !== "Legacy") return countries; // DEVNOTE: Lambda already orders the stations

			const orderedCountries = stationSettings.CountryOrdersPerCulture.find((country) =>
				areEqual(country.Culture, culture)
			).Countries;

			const markets = getMarkets(countries);
			const stations = getAllStations(countries);

			return orderedCountries.map(
				(orderedCountry): ApiCountry => ({
					information: {
						code: orderedCountry,
						name: countries.find((country) => areEqual(country.information.code, orderedCountry))
							.information.name,
					},
					regions: stationSettings.Countries.find((country) =>
						areEqual(country.Code, orderedCountry)
					).Regions.map(
						(region): ApiRegion => ({
							name: getRegionName(region.Name),
							stations: region.Stations.filter(
								(regionStation) => !regionStation.IsHidden && markets[regionStation.Code]?.length > 0
							).map(
								(regionStation): ApiStation => ({
									information: stations.find((station) =>
										areEqual(station.information.code, regionStation.Code)
									)?.information,
									markets: markets[regionStation.Code],
								})
							),
						})
					),
				})
			);
		},

		postStep: async (): Promise<void> => Promise.resolve(),
	});
};
