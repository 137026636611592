import { html } from "haunted";
import i18next from "i18next";
import { useState, useEffect } from "Shared/haunted/CustomHooks";
import { HauntedFunc } from "Shared/haunted/HooksHelpers";
import { PUB_SUBS } from "Services/pub-sub-service/PubSub";
import { unsafeHTML } from "lit-html/directives/unsafe-html";

export const name = "ac-redemption-no-promo-code-modal";
export const useShadowDOM = false;

export const Component: HauntedFunc<{}> = () => {
	const [isOpen, setIsOpen] = useState(false);

	const init = () => {
		const handler = PUB_SUBS.RedemptionNoPromoCodeModalOpened.subscribe(({}) => {
			setIsOpen(true);
		});

		return () => handler.unsubscribe();
	};

	useEffect(init, []);

	const handleContinueButton = () => {
		setIsOpen(false);
		window.location.href = `${window.JetSmart?.AmericanAirlinesSettings?.LoginAddress}`;
	};

	const headerTemplate = () => html`
		<div class="flex h-20 w-full items-center justify-start bg-brand-primary p-5 text-white">
			<i class="jsh-icon jsh-triangle-exclamation-thick mr-5 text-[36px]"></i>
			<span class="text-center text-[24px] font-bold">${i18next.t("Important")}!</span>
		</div>
	`;

	return isOpen
		? html`
				<div class="dg-new-modal">
					<div class="dg-new-modal-content !w-[550px] max-w-[95%]" data-test-id="modal-content">
						<div
							class="dg-new-modal-close"
							data-test-id="modal-close-button"
							@click=${() => setIsOpen(!isOpen)}
						>
							&times;
						</div>
						${headerTemplate()}
						<div class="modal-body">
							<div class="px-6 pt-5 text-center font-body leading-snug text-be-blue-2">
								${unsafeHTML(
									i18next.t("RedemptionNoPromoModalText {{-reg}}", {
										reg: '<span class="relative font-body top-[-1px]">&reg;</span>',
									})
								)}
							</div>
						</div>
						<div class="flex w-full flex-col items-center p-6">
							<button class="dg-rounded-primary-btn" @click=${handleContinueButton}>
								${i18next.t("Continue")}
							</button>
							<span
								class="text-medium md:text-medium mt-3 cursor-pointer select-none text-center text-brand-secondary underline hover:no-underline sm:text-base"
								@click=${() => setIsOpen(!isOpen)}
							>
								${i18next.t("Choose another flight")}
							</span>
						</div>
					</div>
				</div>
		  `
		: html``;
};
