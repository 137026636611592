import { ApiCountry } from "Shared/models/ApiStations";
import { alphabeticalOrderStrategy } from "./alphabeticalOrderStrategy";
import { resourceXmlOrderStrategy } from "./resourceXmlOrderStrategy";
import { useNavitaireSettings } from "ComponentHelpers/useNavitaireSettings";

type StationOrder = "FromResourceXml" | "AlphabeticalAsc";

export interface IStationOrderStrategy {
	orderStations: (countries: ApiCountry[]) => ApiCountry[];
	postStep: () => Promise<void>;
}

export const useStationOrderStrategyFactory = (culture: string) => {
	const settings = useNavitaireSettings();

	const orderStrategyMap = new Map<StationOrder, () => IStationOrderStrategy>([
		["AlphabeticalAsc", alphabeticalOrderStrategy(settings?.value?.abTestSettings)],
		["FromResourceXml", resourceXmlOrderStrategy(settings?.value?.stationSettings, culture)],
	]);

	const getStationOrder = (): StationOrder => {
		const alphabeticalAscVariant = settings?.value?.abTestSettings?.CorporateSetting.FlightSearchStationOrder;
		const variant = window.JetSmart.Variants.find(
			(v) =>
				v.ExperimentId === alphabeticalAscVariant.ExperimentId &&
				v.VariantKey === alphabeticalAscVariant.Variant
		);
		return variant ? "AlphabeticalAsc" : "FromResourceXml";
	};

	const create = (): (() => IStationOrderStrategy) => {
		const order = getStationOrder();
		return orderStrategyMap.get(order);
	};

	return { create };
};
