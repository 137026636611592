import { tealiumLog } from "Services/TealiumHelpers";
import { FARECACHE_NAVITAIRE_SETTINGS_PATH } from "Services/constants";
import { ApiStationSettings } from "Shared/models/ApiStationSettings";
import { ApiNavitaireSettings } from "Shared/models/ApiNavitaireSettings";
import { removeTrailingSlash } from "Services/common";
import { ApiCultureSettings } from "ComponentModels/ApiCultureSettings";
import { createHeaders } from "./HeaderHelper";
import { useSingleton } from "Components/singleton/useSingleton";

export interface NavitaireSettings {
	abTestSettings: AbTestSettings;
	cultureSettings: ApiCultureSettings;
	currency: string;
	stationSettings: ApiStationSettings;
}

export const useNavitaireSettings = () => {
	const settings = useSingleton<NavitaireSettings>({ type: "NavitaireSettings" });

	const init = async (data: { culture: string; loadedValues: NavitaireSettings }) => {
		await settings.init(
			window.JetSmart.NavitaireSettingsOptions.Mode === "DirectFareCache"
				? async () => {
						const result = await fetchNavitaireSettings();

						return {
							abTestSettings: result.AbTestSettings,
							cultureSettings: result.CultureSettings,
							currency: result.DefaultCurrencies.find((c) => c.Culture === data.culture)?.Currency,
							stationSettings: result.StationSettings,
						};
				  }
				: () => Promise.resolve(data.loadedValues)
		);
	};

	const fetchNavitaireSettings = async () => {
		const headers = createHeaders();

		const baseUrl = removeTrailingSlash(window.JetSmart.NavitaireSettingsOptions.BaseAddress);
		const url = `${baseUrl}${FARECACHE_NAVITAIRE_SETTINGS_PATH}`;
		const response = await fetch(url, { headers });

		if (response.ok) {
			const responseJson = (await response.json()) as ApiNavitaireSettings;
			return responseJson;
		} else {
			tealiumLog({
				eventName: "server_error",
				eventParams: {
					page: "/",
					error_code: response.status,
					message: response.statusText,
					server_url: url,
				},
				udoParams: {
					error_info: response.statusText,
					error_code: response.status,
				},
			});

			throw new Error("Network response was not ok.");
		}
	};

	return { value: settings.value, init };
};
