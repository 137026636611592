import { html } from "haunted";
import i18next from "i18next";
import { useState, useEffect } from "Shared/haunted/CustomHooks";
import { HauntedFunc } from "Shared/haunted/HooksHelpers";
import { PUB_SUBS } from "Services/pub-sub-service/PubSub";
import { deleteChileCompraLoginCookie } from "Services/cookieHandling";

export const name = "ac-chile-compra-login-info-modal";
export const useShadowDOM = false;

export const Component: HauntedFunc<{}> = () => {
	const [isOpen, setIsOpen] = useState(false);

	const init = () => {
		const handler = PUB_SUBS.ChileCompraLoginInfoModalOpened.subscribe(() => setIsOpen(true));

		return () => handler.unsubscribe();
	};

	const handleAcceptButton = () => {
		deleteChileCompraLoginCookie();
		setIsOpen(false);
	};

	useEffect(init, []);

	return isOpen
		? html`
				<div class="dg-new-modal">
					<div class="dg-new-modal-content !w-[550px] max-w-[95%]" data-test-id="modal-content">
						<div class="modal-body">
							<div class="flex w-full justify-center py-3">
								<i class="jsh-icon jsh-broken-circle-exclamation text-[100px] text-be-cyan-2"></i>
							</div>
							<div class="px-6 text-center font-body leading-snug text-be-blue-2">
								${i18next.t("ChileCompraModalText")}
							</div>
						</div>
						<div class="flex w-full justify-center p-6">
							<button class="dg-rounded-primary-btn" @click=${handleAcceptButton}>
								${i18next.t("Aceptar")}
							</button>
						</div>
					</div>
				</div>
		  `
		: html``;
};

/*
	@apply p-0 leading-normal;
	width: 413px;
	height: 480px;
	max-width: 95%;
	max-height: 95%;
*/
