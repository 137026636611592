import { PaxViewModel } from "Components/searchbox/pax-selector/usePaxSelector";
import { getTealiumDeviceType, tealiumLog } from "Services/TealiumHelpers";
import dayjs from "dayjs";

export const tealiumLogPaxNumbers = (vm: PaxViewModel) => {
	tealiumLog({
		udoParams: {
			pax_adult_count: vm.adults,
			pax_teen_count: vm.teens,
			pax_children_count: vm.children,
			pax_infant_count: vm.infants,
		},
	});
};

export const tealiumLogPreloadedPromoCode = (promoCode: string) => {
	if (promoCode) {
		tealiumLog({
			udoParams: {
				preloaded_promocode: promoCode,
			},
		});
	}
};

export const tealiumLogSearchClick = (data: {
	adults: number;
	children: number;
	culture: string;
	departureDate: dayjs.Dayjs;
	destinationCode: string;
	infants: number;
	market: string;
	numberOfJourneys: number;
	originCode: string;
	preloadedPromoCode: string;
	promoCode: string;
	returnDate: dayjs.Dayjs;
	teens: number;
}) => {
	tealiumLog({
		eventName: "search_clicked",
		eventParams: {
			culture: data.culture,
			departure_journey_date: data.departureDate ? dayjs(data.departureDate).format("YYYY-MM-DD") : null,
			destination_iata: data.destinationCode,
			device: getTealiumDeviceType(),
			market: data.market,
			origin_iata: data.originCode,
			pax_adult_count: data.adults,
			pax_children_count: data.children,
			pax_infant_count: data.infants,
			pax_teen_count: data.teens,
			promocode: data.promoCode || "n/a",
			return_journey_date: data.returnDate ? dayjs(data.returnDate).format("YYYY-MM-DD") : null,
			trip_type: data.numberOfJourneys === 1 ? "O" : "R",
		},
	});
};

export const tealiumLogSelectReturnType = () => {
	tealiumLog({
		eventName: "trip_type_selected",
		eventParams: {
			trip_type: "R",
			modify: true,
		},
	});
};

export const tealiumLogSelectOneWayType = () => {
	tealiumLog({
		eventName: "trip_type_selected",
		eventParams: {
			trip_type: "O",
			modify: true,
		},
		udoParams: {
			inbound_departure_iata: "-",
			inbound_arrival_iata: "-",
		},
	});
};
