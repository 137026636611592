export function padLeft(num: number, size = 2): string {
	let text = String(Math.abs(num));
	while (text.length < size) {
		text = `0${text}`;
	}

	return num < 0 ? `-${text}` : text;
}

export function padRight(num: number, size = 2) {
	let text = String(Math.abs(num));
	while (text.length < size) {
		text = `${text}0`;
	}

	return num < 0 ? `-${text}` : text;
}

export function isEmpty(text: string) {
	return text === undefined || text === null || text.length === 0;
}

export const areEqual = (first: string, second: string): boolean => {
    return first?.toLowerCase() === second?.toLowerCase();
};
