import { sendAmplitudeExposure } from "Services/amplitude/AmplitudeHelper";
import { ApiCountry } from "Shared/models/ApiStations";
import { IStationOrderStrategy } from "./useStationOrderStrategyFactory";

export const alphabeticalOrderStrategy = (abTestSettings: AbTestSettings): (() => IStationOrderStrategy) => {
	return () => ({
		orderStations: (countries: ApiCountry[]): ApiCountry[] => {
			return countries.map((country) => {
				const stations = country.regions
					.flatMap((r) => r.stations)
					.sort((a, b) => a.information.displayName.localeCompare(b.information.displayName));

				country.regions = [
					{
						name: "",
						stations,
					},
				];

				return country;
			});
		},

		postStep: async (): Promise<void> => {
			await sendAmplitudeExposure([
				{
					ExperimentId: abTestSettings.CorporateSetting.FlightSearchStationOrder.ExperimentId,
					Variant: abTestSettings.CorporateSetting.FlightSearchStationOrder.Variant,
				},
			]);
		},
	});
};
