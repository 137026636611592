import { ApiCountry, ApiStation } from "Shared/models/ApiStations";

export const getCountryAndStationByCode = (allCountries: ApiCountry[], stationCode: string) => {
	try {
		const station = getStationByCode(allCountries, stationCode) || "";

		if (!station) return { station: undefined, countryCode: "" };

		const country = getStationCountryByStationCode(allCountries, stationCode);
		const countryCode = country?.information.code || "";

		return { station, countryCode };
	} catch {
		return { station: undefined, countryCode: "" };
	}
};

export const filterCountriesForPartialString = (countries: ApiCountry[], searchExpression: string): ApiCountry[] =>
	!searchExpression
		? countries
		: countries
				.map((country) => ({
					...country,
					regions: country.regions
						.map((region) => ({
							...region,
							stations: region.stations.filter((station) =>
								isSubstringInDisplayedName(station, searchExpression)
							),
						}))
						.filter((region) => region.stations.length > 0),
				}))
				.filter((country) => country.regions.length > 0);

const isSubstringInDisplayedName = (station: ApiStation, searchExpression: string) =>
	station.information.displayName.toLowerCase().includes(searchExpression.toLowerCase());

const getStationByCode = (allCountries: ApiCountry[], stationCode: string): ApiStation =>
	allCountries.flatMap((c) => c.regions.flatMap((r) => r.stations)).find((s) => s.information.code === stationCode);

const getStationCountryByStationCode = (allCountries: ApiCountry[], stationCode: string): ApiCountry =>
	allCountries.find((c) => c.regions.flatMap((r) => r.stations).find((s) => s.information.code === stationCode));
