import classNames from "classnames";
import i18next from "i18next";
import { html } from "lit-html";
import { CHILEAN_CULTURE_CODE, PERUVIAN_CULTURE_CODE } from "Services/constants";

export interface Props {
	bookingUrl: string;
	absoluteAction: string;
	isOpen: boolean;
	culture: string;
	setIsOpen: (value: boolean) => void;
}

const loginOptionClasses =
	"flex h-[90px] cursor-pointer select-none flex-col items-center justify-between rounded-lg border border-solid border-be-gray-9 p-3 text-[14px] text-brand-secondary hover:bg-gray-100 sm:h-[106px] sm:text-[16px]";

export const useLoginWidget = (props: Props) => {
	// Helpers
	const handlePersonLoginClick = () => {
		const loginUrl = `${props.bookingUrl}/V2/Login?culture=${props.culture}&url=${props.absoluteAction}`;
		window.location.href = loginUrl;
	};

	const handleAmericanLoginClick = () => {
		const loginUrl = window.JetSmart?.AmericanAirlinesSettings?.LoginAddress;
		window.location.href = loginUrl;
	};

	const handlePeruComprasLoginClick = () => {
		const peruCompraLoginUrl = `${props.bookingUrl}/V2/Login?pcompra=1&culture=${props.culture}&url=${props.absoluteAction}`;
		window.location.href = peruCompraLoginUrl;
	};

	const handleBancoLoginClick = () => {
		const bancoLoginUrl = `${props.bookingUrl}/V2/Login?bancoe=1&culture=${props.culture}&url=${props.absoluteAction}`;
		window.location.href = bancoLoginUrl;
	};

	const agencyLoginClick = () => {
		const agencyLoginUrl = `${props.bookingUrl}/V2/Login?agency=1&culture=${props.culture}&url=${props.absoluteAction}`;
		window.location.href = agencyLoginUrl;
	};

	const companyLoginClick = () => {
		const companyLoginUrl = `${props.bookingUrl}/V2/Login?company=1&culture=${props.culture}&url=${props.absoluteAction}`;
		window.location.href = companyLoginUrl;
	};

	// Events

	// Templates

	const separatorTemplate = () => html`<div class="border-b border-solid border-be-gray-9"></div>`;

	const personTemplate = () => html`
		<div class=${loginOptionClasses} @click=${handlePersonLoginClick}>
			<i class="jsh-icon jsh-person mb-2 text-[44px] sm:text-5xl"></i>
			${i18next.t("Personas")}
		</div>
	`;

	const bancoEstadoTemplate = () =>
		props.culture === CHILEAN_CULTURE_CODE
			? html`
					<div class=${loginOptionClasses} @click=${handleBancoLoginClick}>
						<img class="mb-2 w-10 sm:w-12" src="/images/banco-estado/be-only-logo.svg" />
						${i18next.t("Banco Estado")}
					</div>
			  `
			: "";

	const americanAirlinesTemplate = () =>
		window.JetSmart?.DynamicSettings?.IsAmericanOn
			? html`<div class=${loginOptionClasses}>
					<div class="flex h-12 items-center justify-center" @click=${handleAmericanLoginClick}>
						<img class="mb-2 h-4" src="/images/american-airlines/aa-logo.png" />
					</div>
					${i18next.t("AAdvantage® Program")}
			  </div>`
			: "";

	const peruComprasTemplate = () =>
		props.culture === PERUVIAN_CULTURE_CODE
			? html`
					<div class=${loginOptionClasses} @click=${handlePeruComprasLoginClick}>
						<img class="mb-2 h-10" src="/images/peru-compras/pcra-logo-black.svg" />
						${i18next.t("Peru Compras")}
					</div>
			  `
			: "";

	const cugOptionsTemplate = () => html`
		<div class="text-l mb-[17px] flex w-full text-left font-extrabold text-brand-secondary sm:text-xl">
			${i18next.t("Or you can log in as a company or an agency")}:
		</div>
		${separatorTemplate()}
		<div class="flex items-center justify-between pb-1 pt-5 sm:pb-3">
			<div
				class="flex h-[90px] w-[141px] cursor-pointer select-none flex-col items-center justify-center rounded-lg border border-solid border-be-gray-9 p-4 hover:bg-gray-100 sm:h-fit sm:max-h-24 sm:w-64"
				@click=${agencyLoginClick}
			>
				<img class="w-20 sm:w-24" src="/images/cug2/cug-logo-agency-en.svg" />
			</div>
			<div
				class="flex h-[90px] w-[141px] cursor-pointer select-none flex-col items-center justify-center rounded-lg border border-solid border-be-gray-9 p-4 hover:bg-gray-100 sm:h-fit sm:max-h-24 sm:w-64"
				@click=${companyLoginClick}
			>
				<img class="w-20 sm:w-24" src="/images/cug2/cug-logo-company-en.svg" />
			</div>
		</div>
	`;

	const closeButtonTemplate = () =>
		html`
			<div
				class="absolute right-3 top-3 z-10 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-gray-500 font-semibold leading-none text-white hover:bg-gray-600"
				@click=${() => props.setIsOpen(false)}
			>
				&times;
			</div>
		`;

	const htmlTemplate = () => html`
		<div
			class=${classNames("sm:hidden fixed left-0 top-0 h-full w-full bg-black/40", {
				fixed: props.isOpen,
				hidden: !props.isOpen,
			})}
		></div>
		<div
			class=${classNames("absolute bg-white sm:w-160 w-80 sm:left-auto sm:m-0 sm:top-auto common-transition", {
				"rounded-lg sm:rounded-none border border-solid border-be-gray-9": true,
				"mx-auto left-0 right-0 top-[70px]": props.isOpen,
				"sm:right-0": props.isOpen,
				"-right-160": !props.isOpen,
			})}
		>
			<div class="relative p-4 sm:px-8 sm:py-4">
				${closeButtonTemplate()}
				<div class="text-l mb-[17px] flex w-full font-extrabold text-brand-secondary sm:text-xl">
					${i18next.t("Log in with your account as")}:
				</div>
				${separatorTemplate()}
				<div class="justify-center py-5 evenly-spaced-grid sm:grid sm:justify-between">
					${personTemplate()} ${bancoEstadoTemplate()} ${peruComprasTemplate()} ${americanAirlinesTemplate()}
				</div>
				${cugOptionsTemplate()}
			</div>
		</div>
	`;

	return { htmlTemplate };
};
