import { createHeaders } from "ComponentHelpers/HeaderHelper";
import { mapToSchedule } from "ComponentMappers/ScheduleMapper";
import { usePane } from "Components/searchbox/opening-pane/usePane";
import * as dayjs from "dayjs";
import { isMobileResolution, removeTrailingSlash } from "Services/common";
import { API_TIMETABLE_URL } from "Services/constants";
import { useEffect, useState } from "Shared/haunted/CustomHooks";
import { ApiMetadata } from "Shared/models/ApiMetadata";
import { ApiTimeTableV2 } from "Shared/models/ApiTimeTableV2";
import { Schedule } from "Shared/models/Schedule";
import { useNavitaireSettings } from "./useNavitaireSettings";

export interface TimeTableProps {
	destinationCode: string;
	isOneWay: boolean;
	originCode: string;
	forcedUpdateMode: boolean;
	startLoading: () => void;
	stopLoading: (loader: any) => void;
}

export const useTimeTable = (props: TimeTableProps) => {
	const pane = usePane();
	const settings = useNavitaireSettings();

	const [inboundMetadata, setInboundMetadata] = useState<ApiMetadata>(undefined);
	const [inboundSchedules, setInboundSchedules] = useState<Schedule[]>(undefined);
	const [outboundMetadata, setOutboundMetadata] = useState<ApiMetadata>(undefined);
	const [outboundSchedules, setOutboundSchedules] = useState<Schedule[]>(undefined);

	const getApiTimeTable = async (origin: string, destination: string): Promise<ApiTimeTableV2> => {
		const headers = createHeaders();

		const url = generateTimeTableUrl(origin, destination);
		const fetchOptions: RequestInit = {
			method: "GET",
			mode: "cors",
			headers,
		};

		try {
			const result = await fetch(url, fetchOptions);

			if (result.ok) {
				const resultJson = (await result.json()) as ApiTimeTableV2;
				return resultJson;
			}

			return null;
		} catch (e) {
			reset();
			return null;
		}
	};

	const generateTimeTableUrl = (origin: string, destination: string) => {
		const startOfMonth = dayjs().startOf("month").format("YYYY-MM-DD");
		const shouldSearchOneWayOnly =
			!props.forcedUpdateMode && (!window.JetSmart.TimetableOptions.IncludeReturnFlights || props.isOneWay);

		if (window.JetSmart.TimetableOptions.Mode === "DirectFareCache") {
			const baseUrl = removeTrailingSlash(window.JetSmart.TimetableOptions.BaseAddress);

			return [
				`${baseUrl}/timetable?departure=${origin}`,
				`destination=${destination}`,
				`currency=${settings?.value?.currency}`,
				`withPriceRange`,
				`startDate=${startOfMonth}`,
				`${shouldSearchOneWayOnly ? "return=false" : ""}`,
			]
				.filter((item) => item)
				.join("&");
		}

		return [
			`${API_TIMETABLE_URL}?departure=${origin}`,
			`destination=${destination}`,
			`currency=${settings?.value?.currency}`,
			`startDate=${startOfMonth}`,
			`${shouldSearchOneWayOnly ? "return=false" : ""}`,
		]
			.filter((item) => item)
			.join("&");
	};

	const update = async (originCode: string, destinationCode: string) => {
		const loader = props.startLoading();

		try {
			const outbound = await getApiTimeTable(originCode, destinationCode);
			const outboundDates = outbound?.outbound?.map(mapToSchedule) || [];
			if (outbound?.outbound?.length > 0) {
				setOutboundMetadata(outbound.metadata);
				setOutboundSchedules(outbound.outbound.map(mapToSchedule));
			}

			const inbound =
				!window.JetSmart.TimetableOptions.IncludeReturnFlights && !props.isOneWay
					? await getApiTimeTable(destinationCode, originCode)
					: outbound;

			const inboundDates = (
				window.JetSmart.TimetableOptions.IncludeReturnFlights ? inbound?.inbound ?? [] : inbound?.outbound ?? []
			).map(mapToSchedule);

			if (inboundDates.length > 0) {
				setInboundMetadata(inbound.metadata);
				setInboundSchedules(inboundDates);
			}
			return { newOutboundDates: outboundDates, newInboundDates: inboundDates };
		} finally {
			props.stopLoading(loader);
		}
	};

	const reset = () => {
		setInboundMetadata(undefined);
		setInboundSchedules(undefined);
		setOutboundMetadata(undefined);
		setOutboundSchedules(undefined);
	};

	const updateOnSearchReload = async (originCode: string, destinationCode: string) => {
		if (!originCode || !destinationCode) return { newOutboundDates: [], newInboundDates: [] };

		reset();
		return update(originCode, destinationCode);
	};

	const handleRouteChange = (originCode: string, destinationCode: string) => {
		if (props.forcedUpdateMode) return;
		if (!originCode || !destinationCode || !settings?.value?.currency) {
			reset();
			return;
		}

		window.setTimeout(
			() => {
				const doIt = async () => {
					await update(originCode, destinationCode);
					pane.set("departure");
				};

				void doIt();
			},
			isMobileResolution() ? 0 : 350
		);
	};

	useEffect(
		() => handleRouteChange(props.originCode, props.destinationCode),
		[props.originCode, props.destinationCode, settings?.value?.currency]
	);

	return {
		inboundMetadata,
		inboundSchedules,
		outboundMetadata,
		outboundSchedules,
		updateOnSearchReload,
	};
};
