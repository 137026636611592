import { JetSmartEvent } from "Services/eventbus/JetSmartEvent";
import { ApiStation } from "Shared/models/ApiStations";
import { Dayjs } from "dayjs";

export const eventBusTriggerOriginSelected = (city: ApiStation) => {
	window.eventBus.raiseEvent({
		name: JetSmartEvent.OriginStationClick,
		params: {
			id: "searchbox.origin",
			event: "click",
			data: {
				selectedStation: city.information.code,
			},
		},
	});
};

export const eventBusTriggerDestinationSelected = (city: ApiStation) => {
	window.eventBus.raiseEvent({
		name: JetSmartEvent.DestinationStationClick,
		params: {
			id: "searchbox.destination",
			event: "click",
			data: {
				selectedStation: city.information.code,
			},
		},
	});
};

export const eventBusTriggerOutboundDateSelected = (fromDate: Dayjs) => {
	window.eventBus.raiseEvent({
		name: JetSmartEvent.DepartureDateSelected,
		params: {
			id: "searchbox.departureDate",
			event: "click",
			data: {
				selectedDate: fromDate.toDate().toString(),
			},
		},
	});
};

export const eventBusTriggerInboundDateSelected = (toDate: Dayjs) => {
	window.eventBus.raiseEvent({
		name: JetSmartEvent.ReturnDateSelected,
		params: {
			id: "searchbox.returnDate",
			event: "click",
			data: {
				selectedDate: toDate.toDate().toString(),
			},
		},
	});
};

// BOTH

export const eventBusTriggerSelectReturnType = () => {
	window.eventBus.raiseEvent({
		name: JetSmartEvent.RoundTripSelected,
		params: {
			id: "searchbox.roundtrip",
			event: "click",
		},
	});
};

export const eventBusTriggerSelectOneWayType = () => {
	window.eventBus.raiseEvent({
		name: JetSmartEvent.OneWayTripSelected,
		params: {
			id: "searchbox.onewaytrip",
			event: "click",
		},
	});
};
