import { isTabletOrMobileResolution } from "Services/common";
import { TestIdDictionary } from "Services/test-ids/TestIdDictionary";
import { COMMON_DAYJS_FORMAT, INBOUND, OUTBOUND, CULTURES_WITH_SUNDAY_AS_START_OF_WEEK } from "Services/constants";
import i18next from "i18next";
import { classMap } from "lit-html/directives/class-map";
import { useEffect, useMemo, useState } from "haunted";
import "./dc-datepicker.scss";
import { HauntedFunc } from "Shared/haunted/HooksHelpers";
import { html } from "lit-html";
import { Schedule } from "Shared/models/Schedule";
import numberFormatter from "Services/numberFormatter";
import { ApiMetadata } from "Shared/models/ApiMetadata";
import { PaneMode } from "../opening-pane/usePane";
import * as dayjs from "dayjs";
import * as Weekday from "dayjs/plugin/weekday";
dayjs.extend(Weekday);
import * as isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrAfter);
import * as isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);

export const observedAttributes: (keyof Properties)[] = [];
export const useShadowDOM = false;
export const name = "dc-datepicker";

const DEFAULTS: Properties = {
	availableDates: undefined,
	culture: "es-CL",
	isRange: false,
	max: dayjs("2100-12-31", COMMON_DAYJS_FORMAT),
	metadata: undefined,
	min: dayjs("1900-01-01", COMMON_DAYJS_FORMAT),
	paneMode: "none",
	usePrices: false,
	value: undefined,
};

export interface Properties {
	availableDates: Schedule[];
	culture: string;
	isRange?: boolean;
	max?: dayjs.Dayjs;
	metadata: ApiMetadata;
	min?: dayjs.Dayjs;
	paneMode: PaneMode;
	usePrices: boolean;
	value: dayjs.Dayjs | dayjs.Dayjs[];
}

interface ChangeEventDetail {
	date: dayjs.Dayjs;
	fromDate: dayjs.Dayjs;
	toDate: dayjs.Dayjs;
}

interface ChangeSelectedPriceDetail {
	price: string;
}

export class ChangeDateEvent extends CustomEvent<ChangeEventDetail> {
	constructor(detail: ChangeEventDetail) {
		super("changeDate", { detail });
	}
}

export class ChangeSelectedPriceEvent extends CustomEvent<ChangeSelectedPriceDetail> {
	constructor(detail: ChangeSelectedPriceDetail) {
		super("changePrice", { detail });
	}
}

const mapProperties = (host: Element & Properties) => {
	const convertToDayJs = (value: string | dayjs.Dayjs | string[] | dayjs.Dayjs[]): dayjs.Dayjs | dayjs.Dayjs[] => {
		if (Array.isArray(value)) {
			return value.map((v) => convertToDayJs(v)) as dayjs.Dayjs[];
		}

		if (dayjs.isDayjs(value)) {
			return dayjs(value);
		}

		throw new Error(`Cannot parse value: ${value}`);
	};

	const props: Properties = {
		availableDates: host.availableDates !== undefined ? host.availableDates : DEFAULTS.availableDates,
		culture: host.culture !== undefined ? host.culture : DEFAULTS.culture,
		isRange: Boolean(host.isRange),
		max: host.max !== undefined ? host.max : DEFAULTS.max,
		metadata: host.metadata,
		min: host.min !== undefined ? host.min : DEFAULTS.min,
		paneMode: host.paneMode,
		usePrices: host.usePrices,
		value: host.value !== undefined ? host.value : DEFAULTS.value,
	};

	return props;
};

export const Component: HauntedFunc<Properties> = (host) => {
	const props = mapProperties(host);

	// HELPERS

	const init = () => {
		const id = `_${name}_${Math.random().toString(36).substr(2, 9)}`;
		setComponentId(id);
	};

	const collectDisplayedDates = () => {
		const calendarMonths = isTabletOrMobileResolution()
			? [currentDate]
			: [currentDate, dayjs(currentDate).add(1, "month")];

		return props.availableDates?.filter((date) => {
			const formattedDayJsDate = dayjs.isDayjs(date.departureDate)
				? date.departureDate
				: dayjs(date.departureDate);
			return calendarMonths.some(
				(month) =>
					formattedDayJsDate.isSameOrAfter(month.startOf("month")) &&
					formattedDayJsDate.isSameOrBefore(month.endOf("month"))
			);
		});
	};

	const isDayDisabled = (day: dayjs.Dayjs) =>
		props.availableDates &&
		(day.isBefore(minDate(), "date") ||
			day.isAfter(maxDate(), "date") ||
			!props.availableDates.some((schedule) => {
				const dayJsDate = dayjs.isDayjs(schedule.departureDate)
					? schedule.departureDate
					: dayjs(schedule.departureDate);
				return dayJsDate.isSame(day, "date");
			}));

	const weekdays = () => {
		const days = [
			i18next.t("mo"),
			i18next.t("tu"),
			i18next.t("we"),
			i18next.t("th"),
			i18next.t("fr"),
			i18next.t("sa"),
			i18next.t("su"),
		];

		if (doesWeekStartOnSunday()) {
			days.unshift(days.pop());
		}

		return days;
	};

	const move = (direction: number) => {
		setCurrentDate(dayjs(currentDate).add(direction, "month"));
	};

	const doesWeekStartOnSunday = () => CULTURES_WITH_SUNDAY_AS_START_OF_WEEK.includes(props.culture.toLowerCase());

	const getNewRangeFromOneDate = (existingDay: dayjs.Dayjs, newDay: dayjs.Dayjs) => {
		if (existingDay && newDay.isSame(existingDay, "day")) {
			return [undefined, undefined];
		}

		if (existingDay && newDay.isAfter(existingDay, "day")) {
			return [existingDay, newDay];
		}

		return [newDay, existingDay];
	};

	const getNewRangeDates = (day: dayjs.Dayjs) => {
		const [start, end] = selectedDate as dayjs.Dayjs[];

		if (!start) {
			return getNewRangeFromOneDate(end, day);
		}

		if (!end) {
			return getNewRangeFromOneDate(start, day);
		}

		return [start, day];
	};

	const minDate = () => (dayjs.isDayjs(props.min) ? props.min : dayjs(props.min, COMMON_DAYJS_FORMAT));

	const maxDate = () => (dayjs.isDayjs(props.max) ? props.max : dayjs(props.max, COMMON_DAYJS_FORMAT));

	const isSelectedDayAfterHoveredDay = (day: dayjs.Dayjs) =>
		props.isRange &&
		Array.isArray(selectedDate) &&
		selectedDate[INBOUND] &&
		day.isSame(selectedDate[INBOUND], "day") &&
		hoveredDay &&
		hoveredDay.isBefore(day);

	const isDaySelected = (day: dayjs.Dayjs) => {
		if (!selectedDate || isSelectedDayAfterHoveredDay(day)) {
			return false;
		}

		if (Array.isArray(selectedDate)) {
			return selectedDate.some((date) => date && date.isSame(day, "day"));
		}

		return day.isSame(selectedDate as dayjs.Dayjs, "day");
	};

	const isDayRangeStart = (day: dayjs.Dayjs) => {
		if (!props.isRange || !selectedDate || !Array.isArray(selectedDate) || !selectedDate[OUTBOUND]) {
			return false;
		}

		return day.isSame(selectedDate[OUTBOUND], "day");
	};

	const isDayRangeEnd = (day: dayjs.Dayjs) => {
		if (!props.isRange || !selectedDate || !Array.isArray(selectedDate) || !selectedDate[INBOUND]) {
			return false;
		}

		return day.isSame(selectedDate[INBOUND], "day") && !day.isSame(selectedDate[OUTBOUND], "day");
	};

	const isDayToday = (day: dayjs.Dayjs) => day.isSame(dayjs(), "date");

	const isDayInHoveredRange = (day: dayjs.Dayjs) => {
		return (
			props.isRange &&
			Array.isArray(selectedDate) &&
			selectedDate[OUTBOUND] &&
			day.isAfter(selectedDate[OUTBOUND], "day") &&
			day.isBefore(hoveredDay, "day")
		);
	};

	const isDayInRange = (day: dayjs.Dayjs) =>
		Array.isArray(selectedDate) &&
		selectedDate[OUTBOUND] &&
		selectedDate[INBOUND] &&
		day.isAfter(selectedDate[OUTBOUND], "day") &&
		day.isBefore(selectedDate[INBOUND], "day");

	const isBackOneMonthDisabled = () =>
		minDate().year() === currentDate.year() && minDate().month() >= currentDate.month();

	const isForwardOneMonthDisabled = () => {
		return (
			maxDate().year() === currentDate.year() &&
			maxDate().month() <= currentDate.month() + (isTabletOrMobileResolution() ? 0 : 1)
		);
	};

	const addPlacerholderDaysInFront = (index: number) =>
		Array.from(Array(doesWeekStartOnSunday() ? (index + 7) % 7 : index));

	const addPlacerholderDaysAfter = (index: number) =>
		Array.from(Array(6 - (doesWeekStartOnSunday() ? (index + 7) % 7 : index)));

	const getCalendarForMonth = (offset: number): dayjs.Dayjs[][] => {
		const offsetDate = dayjs(currentDate).add(offset, "month");

		const firstDayIndex = dayjs(offsetDate).startOf("month").weekday();
		let days: dayjs.Dayjs[] = addPlacerholderDaysInFront(firstDayIndex);

		let day = dayjs(offsetDate).startOf("month");
		const endOfMonth = dayjs(offsetDate).endOf("month");

		while (day.isSameOrBefore(endOfMonth)) {
			days.push(dayjs(day));
			day = dayjs(day.add(1, "day"));
		}

		const lastDayIndex = dayjs(offsetDate).endOf("month").weekday();
		days = days.concat(addPlacerholderDaysAfter(lastDayIndex));

		const retVal = days.reduce((weeks, weekDay, i) => {
			const weekIndex = Math.floor(i / 7);
			weeks[weekIndex] = [].concat(weeks[weekIndex] || [], weekDay);
			return weeks;
		}, []);

		return retVal;
	};

	const getInitialSelectedDate = () => {
		if (props.isRange && props.value && !Array.isArray(props.value)) {
			throw new Error("If the datepicker is set to Range, the value should be an array.");
		}

		if (!props.isRange && props.value && Array.isArray(props.value)) {
			throw new Error("If the datepicker is NOT set to Range, the value should NOT be an array.");
		}

		if (!props.value) {
			return props.isRange ? [undefined, undefined] : undefined;
		}

		if (Array.isArray(props.value)) {
			return props.value.reduce(
				(aggr, day) =>
					aggr.concat(day ? (dayjs.isDayjs(day) ? dayjs(day) : dayjs(day, COMMON_DAYJS_FORMAT)) : undefined),
				[]
			);
		}

		return dayjs.isDayjs(props.value) ? dayjs(props.value) : dayjs(props.value, COMMON_DAYJS_FORMAT);
	};

	const initialCurrentDate = () => {
		const value = Array.isArray(props.value) ? props.value[OUTBOUND] : props.value;

		return value
			? dayjs.isDayjs(value)
				? value
				: dayjs(value, COMMON_DAYJS_FORMAT)
			: dayjs().isSameOrAfter(minDate(), "date") && dayjs().isSameOrBefore(maxDate(), "date")
			? dayjs()
			: minDate();
	};

	const updateInputField = () => {
		if (inputElem()) {
			inputElem().value = (props.value as dayjs.Dayjs).format(COMMON_DAYJS_FORMAT);
		}
	};

	const inputElem = () => document.getElementById(componentId) as HTMLInputElement;

	const formatSelectedPrice = (day: dayjs.Dayjs) => {
		const price = props.availableDates?.find((schedule) => schedule.departureDate.isSame(day, "day")).price;

		return price ? price.toString().trim() : undefined;
	};

	// EVENT HANDLERS

	// DEVNOTE All this checking if day is of type Dayjs is because sometimes strange change events are fired
	// TODO Try to eliminate unwanted change events
	const dispatchChange = (value: dayjs.Dayjs | dayjs.Dayjs[], selectedPrice?: string) => {
		const date = !Array.isArray(value) && dayjs.isDayjs(value) ? value : undefined;
		const fromDate =
			Array.isArray(value) && value.every((d) => !d || dayjs.isDayjs(d)) && value.length > 0
				? value[OUTBOUND]
				: undefined;
		const toDate =
			Array.isArray(value) && value.every((d) => !d || dayjs.isDayjs(d)) && value.length > 1
				? value[INBOUND]
				: undefined;

		host.dispatchEvent(
			new ChangeDateEvent({
				date,
				fromDate,
				toDate,
			})
		);
		if (selectedPrice) {
			host.dispatchEvent(
				new ChangeSelectedPriceEvent({
					price: selectedPrice,
				})
			);
		}
	};

	const handleMouseEnter = (day: dayjs.Dayjs) => {
		if (hoveredDay?.isSame(day, "day")) {
			return;
		}

		setHoveredDay(day);
	};

	const handleMouseLeave = (day: dayjs.Dayjs) => {
		if (!hoveredDay?.isSame(day, "day")) {
			return;
		}

		setHoveredDay(undefined);
	};

	const handleBack = (e: MouseEvent) => {
		e.stopPropagation();

		if (isBackOneMonthDisabled()) {
			return;
		}

		move(-1);
	};

	const handleForward = (e: MouseEvent) => {
		e.stopPropagation();

		if (isForwardOneMonthDisabled()) {
			return;
		}

		move(1);
	};

	const handleDateClick = (e: MouseEvent, day: dayjs.Dayjs) => {
		e.stopPropagation();

		if (isDayDisabled(day)) {
			return;
		}

		if (Array.isArray(selectedDate)) {
			const [start, end] = getNewRangeDates(day);
			const newValues = [start ? dayjs(start) : undefined, end ? dayjs(end) : undefined];
			setSelectedDate(newValues);

			dispatchChange(newValues, formatSelectedPrice(end));
		} else {
			const newValue = dayjs(day);
			setSelectedDate(newValue);

			dispatchChange(newValue, formatSelectedPrice(newValue));
		}

		setCurrentDate(dayjs(day));

		if (inputElem()) {
			inputElem().classList.remove("invalid");
		}
	};

	// COMPONENT

	const [componentId, setComponentId] = useState<string>("");
	const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | dayjs.Dayjs[]>(getInitialSelectedDate());
	const [currentDate, setCurrentDate] = useState<dayjs.Dayjs>(initialCurrentDate());
	const [hoveredDay, setHoveredDay] = useState<dayjs.Dayjs>(undefined);

	const lowestDisplayedPriceSchedule = useMemo(() => {
		const displayedDates = collectDisplayedDates();
		if (!displayedDates || displayedDates.length === 0) {
			return undefined;
		}
		return displayedDates.reduce((acc, curr) => {
			if (acc.price < curr.price) {
				return acc;
			}
			return curr;
		}, displayedDates[0]);
	}, [props.availableDates, currentDate]);

	useEffect(() => updateInputField, [props.value]);

	useEffect(init, []);

	// TEMPLATES

	const navigationPlaceholderTemplate = () => {
		const tempClassMap = classMap({
			"dg-dp-square": true,
			"dg-dp-navigation": true,
			"dg-dp-disabled": true,
			"hidden-sm-down": true,
		});

		return html`<span class=${tempClassMap}></span>`;
	};

	const forwardOneMonthTemplate = (hiddenOnDesktop = false) => {
		const tempClassMap = classMap({
			"dg-dp-month-forward": true,
			"dg-dp-square": true,
			"dg-dp-navigation": true,
			"dg-dp-disabled": isForwardOneMonthDisabled(),
			"hidden-md-up": hiddenOnDesktop,
		});

		return html`<span
			class=${tempClassMap}
			data-test-id=${TestIdDictionary.Date.MoveForward}
			@click=${handleForward}
		></span>`;
	};

	const backOneMonthTemplate = (hiddenOnDesktop = false) => {
		const tempClassMap = classMap({
			"dg-dp-month-back": true,
			"dg-dp-square": true,
			"dg-dp-navigation": true,
			"dg-dp-disabled": isBackOneMonthDisabled(),
			"hidden-md-up": hiddenOnDesktop,
		});

		return html`<span
			class=${tempClassMap}
			@click=${handleBack}
			data-test-id=${TestIdDictionary.Date.MoveBack}
		></span>`;
	};

	const firstMonthNavigationTemplate = () => html`
		<div class="w-full grid grid-cols-3 items-center justify-center">
			${backOneMonthTemplate()}
			<span class="dg-dp-unit-nav">
				<span
					class="font-bold"
					data-test-id=${TestIdDictionary.Date.MonthName}
					data-test-value=${currentDate.format("YYYY-MM")}
				>
					${dayjs(currentDate).format("MMMM")}&nbsp;
				</span>
				${dayjs(currentDate).format("YYYY")}
			</span>
			${navigationPlaceholderTemplate()} ${forwardOneMonthTemplate(true)}
		</div>
	`;

	const secondMonthNavigationTemplate = () => {
		const dateToDisplay = dayjs(currentDate).add(1, "month");

		return html`<div class="w-full grid grid-cols-3 items-center justify-center">
			${backOneMonthTemplate(true)} ${navigationPlaceholderTemplate()}
			<span class="dg-dp-unit-nav">
				<span
					class="font-bold"
					data-test-id=${TestIdDictionary.Date.MonthName}
					data-test-value=${dateToDisplay.format("YYYY-MM")}
				>
					${dateToDisplay.format("MMMM")}&nbsp;
				</span>
				${dateToDisplay.format("YYYY")}
			</span>
			${forwardOneMonthTemplate()}
		</div>`;
	};

	const weekdaysHeaderTemplate = () => html`<div class="w-full grid grid-cols-7">
		${weekdays().map((weekday) => html`<span class="dg-dp-square dg-dp-col-header">${weekday}</span>`)}
	</div>`;

	const weekTemplate = (week: dayjs.Dayjs[]) =>
		html` <div class="w-full grid grid-cols-7">${week.map(dayTemplate)}</div> `;

	const dayTemplate = (day: dayjs.Dayjs) => {
		const currentSchedule = day
			? props.availableDates?.find((schedule) => schedule.departureDate.isSame(day, "day"))
			: undefined;

		return day
			? html`<span
					class="${classMap({
						"dg-dp-square": true,
						"dg-dp-date": true,
						"dg-dp-selected": isDaySelected(day),
						"dg-dp-today": isDayToday(day),
						"dg-dp-disabled": isDayDisabled(day),
						"dg-dp-range-start": isDayRangeStart(day),
						"dg-dp-in-range": (!hoveredDay && isDayInRange(day)) || isDayInHoveredRange(day),
						"dg-dp-range-end": !hoveredDay && isDayRangeEnd(day),
						"dg-dp-hover-dg-dp-range-end": props.isRange,
						"lowest-price":
							props.usePrices && currentSchedule?.price === lowestDisplayedPriceSchedule?.price,
					})}"
					@mouseenter=${() => handleMouseEnter(day)}
					@mouseleave=${() => handleMouseLeave(day)}
					@click=${(e: MouseEvent) => handleDateClick(e, day)}
					data-test-id=${TestIdDictionary.Date.Date}
					data-test-value=${day.format(COMMON_DAYJS_FORMAT)}
			  >
					${day.format("DD")}
					${props.usePrices
						? html`<span
								>${currentSchedule && currentSchedule.price
									? numberFormatter({
											amount: currentSchedule.price,
											currency: currentSchedule.currency,
											culture: props.culture,
											leadingSign: true,
											forcedToRemoveDecimals: true,
									  }).replace(" ", "")
									: ""}</span
						  >`
						: ""}
			  </span>`
			: html`<span class="dg-dp-square">&nbsp;</span>`;
	};

	const weeksTemplate = (offset = 0) => html`${getCalendarForMonth(offset).map(weekTemplate)}`;

	return html` <div class="flex flex-col">
		<div class="dg-dp-months-container">
			<div class="dg-dp-month" data-test-id=${TestIdDictionary.Date.MonthContainer}>
				${firstMonthNavigationTemplate()} ${weekdaysHeaderTemplate()} ${weeksTemplate()}
				<div class="separator-line"></div>
			</div>
			<div class="dg-dp-month hidden-sm-down" data-test-id=${TestIdDictionary.Date.MonthContainer}>
				${secondMonthNavigationTemplate()} ${weekdaysHeaderTemplate()} ${weeksTemplate(1)}
			</div>
		</div>
	</div>`;
};
