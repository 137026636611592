import { html } from "haunted";
import { useState, useEffect } from "Shared/haunted/CustomHooks";
import { HauntedFunc } from "Shared/haunted/HooksHelpers";

import { clone, getParsedProperty } from "Services/common";

export const useShadowDOM = false;
export const name = "ac-dynamic-promo-codes";

interface DynamicPromoCode {
	index: number;
	programLevel: string;
	code: string;
	culture: string;
	roleCode: string;
}

const invalidClassName = "invalid";
const cultureElemPrefix = "dynamic-promo-code-culture-";
const roleElemPrefix = "dynamic-promo-code-role-";
const levelElemPrefix = "dynamic-promo-code-level-";
const codeElemPrefix = "dynamic-promo-code-code-";

export const observedAttributes: (keyof Attributes)[] = ["promo-codes"];

export interface Attributes {
	"promo-codes": string;
}

export interface Props {
	promoCodes: DynamicPromoCode[];
}

export const Component: HauntedFunc<Props> = (host) => {
	const props: Props = {
		promoCodes:
			(typeof host.promoCodes === "string" ? getParsedProperty<DynamicPromoCodeSetting[]>(host.promoCodes) : [])
				?.filter((setting) => setting)
				.map((setting, i) => ({
					index: i,
					programLevel: setting.ProgramCodeAndLevel || "",
					code: setting.Code || "",
					culture: setting.Culture || "",
					roleCode: setting.RoleCode || "",
				})) || [],
	};

	// HELPERS

	const addEmpty = () => {
		setPromoCodes([
			...promoCodes,
			{
				index: promoCodes.length,
				programLevel: "",
				code: "",
				culture: "",
				roleCode: "",
			},
		]);
	};

	const validate = () => {
		let isValid = true;

		for (let i = 0; i < promoCodes.length; i++) {
			const cultureElem = document.getElementById(`${cultureElemPrefix}${i}`) as HTMLInputElement;
			const roleElem = document.getElementById(`${roleElemPrefix}${i}`) as HTMLInputElement;
			const levelElem = document.getElementById(`${levelElemPrefix}${i}`) as HTMLInputElement;
			const codeElem = document.getElementById(`${codeElemPrefix}${i}`) as HTMLInputElement;

			codeElem.classList.remove(invalidClassName);
			cultureElem.classList.remove(invalidClassName);
			roleElem.classList.remove(invalidClassName);
			levelElem.classList.remove(invalidClassName);

			if ((cultureElem.value || roleElem.value || levelElem.value) && !codeElem.value) {
				codeElem.classList.add(invalidClassName);
				isValid = false;
			}

			if (!cultureElem.value && !roleElem.value && !levelElem.value && codeElem.value) {
				cultureElem.classList.add(invalidClassName);
				roleElem.classList.add(invalidClassName);
				levelElem.classList.add(invalidClassName);
				isValid = false;
			}
		}

		return isValid;
	};

	// EVENT HANDLERS

	const handleSave = (e: MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();

		if (validate()) {
			(document.getElementById("actionType") as HTMLInputElement).value = "save_dynamic_settings";
			(document.getElementById("admin_form") as HTMLFormElement).submit();
		}
	};

	const deleteRow = (index: number) => {
		const newPromoCodes = clone(promoCodes).filter((pc) => pc.index !== index);
		newPromoCodes.forEach((pc, i) => (pc.index = i));
		setPromoCodes(newPromoCodes);
	};

	const handleInput = (e: KeyboardEvent) => {
		const grandParentElement = (e.target as HTMLInputElement).parentElement.parentElement;
		const inputs = Array.from(grandParentElement.querySelectorAll("input"));
		inputs.forEach((input) => input.classList.remove(invalidClassName));
	};

	// COMPONENT

	const [promoCodes, setPromoCodes] = useState<DynamicPromoCode[]>(props.promoCodes);

	useEffect(addEmpty, []);

	// TEMPLATES

	const cultureTemplate = (promoCode: DynamicPromoCode) => {
		const id = `${cultureElemPrefix}${promoCode.index}`;
		return html`<div class="col-xs-1 col-sm-2-9">
			<label for=${id}><br />Culture</label>
			<input
				name=${`DynamicPromoCodes[${promoCode.index}].Culture`}
				id=${id}
				value=${promoCode.culture || ""}
				@input=${handleInput}
			/>
		</div>`;
	};

	const roleCodeTemplate = (promoCode: DynamicPromoCode) => {
		const id = `${roleElemPrefix}${promoCode.index}`;
		return html`<div class="col-xs-1 col-sm-2-9">
			<label for=${id}><br />Role Code</label>
			<input
				name=${`DynamicPromoCodes[${promoCode.index}].RoleCode`}
				id=${id}
				value=${promoCode.roleCode || ""}
				@input=${handleInput}
			/>
		</div>`;
	};

	const programLevelTemplate = (promoCode: DynamicPromoCode) => {
		const id = `${levelElemPrefix}${promoCode.index}`;
		return html`<div class="col-xs-1 col-sm-2-9">
			<label for=${id}>Prg Code-Level (BEC-SMT)</label>
			<input
				name=${`DynamicPromoCodes[${promoCode.index}].ProgramCodeAndLevel`}
				id=${id}
				value=${promoCode.programLevel || ""}
				@input=${handleInput}
			/>
		</div>`;
	};

	const codeTemplate = (promoCode: DynamicPromoCode) => {
		const id = `${codeElemPrefix}${promoCode.index}`;
		return html`<div class="col-xs-1 col-sm-2-9">
			<label for=${id}><br />Promo Code</label>
			<input
				name=${`DynamicPromoCodes[${promoCode.index}].Code`}
				id=${id}
				value=${promoCode.code || ""}
				@input=${handleInput}
			/>
		</div>`;
	};

	const rowTemplate = (promoCode: DynamicPromoCode) => html`<div class="row">
		${cultureTemplate(promoCode)} ${roleCodeTemplate(promoCode)} ${programLevelTemplate(promoCode)}
		${codeTemplate(promoCode)} ${buttonsTemplate(promoCode)}
	</div>`;

	const buttonsTemplate = (promoCode: DynamicPromoCode) => html`<div class="col-xs-1 col-sm-1-9">
		<div class="dpc-buttons">${deleteButtonTemplate(promoCode)} ${addButtonTemplate(promoCode)}</div>
	</div>`;

	const deleteButtonTemplate = (promoCode: DynamicPromoCode) =>
		promoCode.index < promoCodes.length - 1
			? html`<div @click=${() => deleteRow(promoCode.index)}>Delete</div>`
			: "";

	const addButtonTemplate = (promoCode: DynamicPromoCode) =>
		promoCode.index === promoCodes.length - 1 ? html`<div @click=${addEmpty}>Add</div>` : "";

	const submitButtonTemplate = () => html`<div class="row">
		<div class="col-xs-1 col-md-4-5">
			<div class="sj-save-warning">
				* After saving, a page refresh (F5) may be necessary to properly reflect the saved state.
			</div>
		</div>
		<div class="col-xs-1 col-sm-1-5 main-buttons">
			<button type="submit" value="Save" class="btn-primary push-down" @click=${handleSave}>Save</button>
		</div>
	</div>`;

	return html` ${promoCodes.map(rowTemplate)} ${submitButtonTemplate()} `;
};
