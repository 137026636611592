import { useSingleton } from "Components/singleton/useSingleton";

export type PaneMode = "none" | "origin" | "destination" | "departure" | "return" | "passengers";

export const usePane = () => {
	const mode = useSingleton<PaneMode>({ type: "PaneMode" });
	const lastOpenMode = useSingleton<PaneMode>({ type: "LastOpenPaneMode" });

	const closePane = () => mode.update("none");

	const set = (newMode: PaneMode) => {
		if (mode.value === newMode) return;

		if (newMode === "none") {
			closePane();
			return;
		}

		// DEVNOTE For animation, we first close
		// and then open the new mode
		closePane();
		window.setTimeout(() => {
			mode.update(newMode);
			lastOpenMode.update(newMode);
		}, 350);
	};

	return { lastOpenMode: lastOpenMode.value, mode: mode.value, set };
};
